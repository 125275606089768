/* eslint-disable */
export default {
  // Custom Translations
  api: {
    authentication: {
      errors: {
        authenticate: "Erro na autenticação",
        forgot: "Erro na requisição",
        reset: "Erro na requisição",
      },
    },
    user: {
      errors: {
        create: "Erro ao criar usuário",
        update: "Erro ao atualizar usuário",
        validate: "Erro ao validar usuário",
        get: "Erro ao buscar usuários",
        getById: "Erro ao buscar o usuário",
      },
    },
    services: {
      errors: {
        create: "Erro ao criar serviço",
        update: "Erro ao atualizar serviço",
        validate: "Erro ao validar serviço",
        get: "Erro ao buscar serviços",
        getById: "Erro ao buscar o serviço",
      },
    },
    lecom: {
      errors: {
        getToken: "Erro ao buscar o Token de acesso",
        getModels: "Erro ao buscar modelos",
        getService: "Erro ao buscar modelos",
        getPending: "Erro ao buscar processos pendentes",
        getDone: "Erro ao buscar processos encerrados",
        getForm: "Erro ao buscar formulário",
        getProgress: "Erro ao buscar processos em andamento",
        postOpenProcess: "Erro ao criar novo processo",
      },
    },
    portal: {
      errors: {
        getToken: "Erro ao buscar o Token de acesso",
        initProcess: "Erro ao iniciar processo",
        getProcessInstance: "Erro ao buscar a instância do processo",
      },
    },
    layoutConfiguration: {
      errors: {
        get: "Erro nas configurações de layout",
        primary: "Erro ao atualizar a cor primária",
        secondary: "Erro ao atualizar a cor primária",
        logoImage: "Erro ao atualizar a imagem da logo",
        bannerImage: "Erro ao atualizar a imagem do banner",
      },
    },
    emailConfiguration: {
      errors: {
        get: "Erro nas configurações de e-mail",
        smtpServer: "Erro ao atualizar o Servidor SMTP",
        smtpPort: "Erro ao atualizar a Porta SMTP",
        smtpEmail: "Erro ao atualizar o E-mail SMTP",
        smtpPassword: "Erro ao atualizar a Senha SMTP",
        fromEmail: "Erro ao atualizar o E-mail do remetente",
        useTls: "Erro ao atualizar o uso do SSL/TLS",
      },
    },
    groups: {
      errors: {
        get: "Erro nos grupos",
      },
    },
    apiConfiguration: {
      errors: {
        get: "Erro nas configurações do BPM",
        pathServiceLecomModel: "Erro ao atualizar o Path do modelo do BPM",
        pathServiceLecomAuthentication: "Erro ao atualizar o Path de autenticação do BPM",
        pathServiceLecomOpenProcess: "Erro ao atualizar o Path de abertura de processo do BPM",
        pathServiceLecomProcessInstances: "Erro ao atualizar o Path da instancia de processo do BPM",
        pathServiceLecomFormApp: "Erro ao atualizar o Path do form do BPM",
        serviceLecomAuthenticationAdminLogin: "Erro ao atualizar o Login do administrador do BPM",
        serviceLecomAuthenticationAdminPassword: "Erro ao atualizar o Senha do administrador do BPM",
        serviceLecomUserPublicLogin: "Erro ao atualizar o Login do usuário publico do BPM",
        serviceLecomUserPublicPassword: "Erro ao atualizar o Senha do usuário publico do BPM",
        serviceLecomUserPublicId: "Erro ao atualizar o ID do usuário publico do BPM",
        urlServiceLecomBpm: "Erro ao atualizar o Url do BPM",
      },
    },
    parameterConfiguration: {
      errors: {
        get: "Erro nas configurações do parâmetro",
        authenticationType: "Erro ao atualizar o tipo de autenticação do login",
        loginParameterUrl: "Erro ao atualizar a URL de redirecionamento do Parâmetro de login",
        loginParameterApiUrl: "Erro ao atualizar a URL da API do Parâmetro de login",
        loginParameterApiUser: "Erro ao atualizar o usuário da API do Parâmetro de login",
        loginParameterApiPassword: "Erro ao atualizar a senha da API do Parâmetro de login",
        oauthPkceUrl: "Erro ao atualizar a URL da API do Oauth PKCE",
        oauthPkceAuthorizeUrl: "Erro ao atualizar a URL Authorize da API do Oauth PKCE",
        oauthPkceTokenUrl: "Erro ao atualizar a URL Token da API do Oauth PKCE",
        oauthPkceUserInfoUrl: "Erro ao atualizar a URL UserInfo da API do Oauth PKCE",
        oauthPkceLogoutUrl: "Erro ao atualizar a URL Logout da API do Oauth PKCE",
        oauthPkcePostLogoutRedirectUrl: "Erro ao atualizar a URL PostLogoutRedirectUrl da API do Oauth PKCE",
        oauthPkceRedirectUri: "Erro ao atualizar a URL RedirectUri da API do Oauth PKCE",
        oauthPkceClientId: "Erro ao atualizar o ClientID da API do Oauth PKCE",
        oauthPkceClientSecret: "Erro ao atualizar o Secret da API do Oauth PKCE",
        oauthPkceGrantType: "Erro ao atualizar o GrantType da API do Oauth PKCE",
        oauthPkceScope: "Erro ao atualizar o Scopes da API do Oauth PKCE",
        oauthPkceIdField: "Erro ao atualizar o IdField da API do Oauth PKCE",
        govbrOauthPkceUrl: "Erro ao atualizar a URL da API do Gov BR",
        govbrOauthPkceAuthorizeUrl: "Erro ao atualizar a URL Authorize da API do Gov BR",
        govbrOauthPkceTokenUrl: "Erro ao atualizar a URL Token da API do Gov BR",
        govbrOauthPkceUserInfoUrl: "Erro ao atualizar a URL UserInfo da API do Gov BR",
        govbrOauthPkceLogoutUrl: "Erro ao atualizar a URL Logout da API do Gov BR",
        govbrOauthPkcePostLogoutRedirectUrl: "Erro ao atualizar a URL PostLogoutRedirectUrl da API do Gov BR",
        govbrOauthPkceRedirectUri: "Erro ao atualizar a URL RedirectUri da API do Gov BR",
        govbrOauthPkceClientId: "Erro ao atualizar o ClientID da API do Gov BR",
        govbrOauthPkceClientSecret: "Erro ao atualizar o Secret da API do Gov BR",
        govbrOauthPkceGrantType: "Erro ao atualizar o GrantType da API do Gov BR",
        govbrOauthPkceScope: "Erro ao atualizar o Scopes da API do Gov BR",
        govbrOauthPkceIdField: "Erro ao atualizar o IdField da API do Gov BR",
        urlAmbienteFront: "Erro ao atualizar a URL do ambiente de Front-end",
        apiTokenUser: "Erro ao atualizar o usuário da API do Portal",
        apiTokenPassword: "Erro ao atualizar a senha da API do Portal",
        authLayoutDirection: "Erro ao atualizar a direção do Layout das tela de autenticação",
        failedLoginCount: "Erro ao atualizar a quantia de tentavidas de login",
      },
    },
    contentConfiguration: {
      errors: {
        get: "Erro nas configurações de conteúdo",
        termsCondition: "Erro ao atualizar os termos e condições",
      },
    },
    tabs: {
      errors: {
        getById: "Erro ao buscar a aba",
        get: "Erro ao buscar abas",
      },
    },
  },
  process: {
    register: "Iniciar Processo",
    view: "Visualizar Processo",
    answer: "Responder Processo",
    fields: {
      processInstanceId: "Num do Processo", 
      service: "Serviço", 
      nameActivity: "Etapa", 
      deadline: "Prazo", 
      user: "Responsável", 
      createdAt: "Data de Criação", 
      notes: "Observações", 
      status: "Status", 
    },
    notifications: {
      accept: {
        title: "Processo aprovado",
        message: "Processo aprovado com sucesso",
      },
      cancel: {
        title: "Processo cancelado",
        message: "Processo cancelado com sucesso",
      },
      reject: {
        title: "Processo rejeitado",
        message: "Processo rejeitado com sucesso",
      },
      finish: {
        title: "Processo finalizado",
        message: "Processo finalizado com sucesso",
      },
      success: {
        title: "Dados registrados com sucesso!",
        message: "Dados registrados com sucesso.",
      },
      error: {
        initProcess: {
          title: "Serviço indisponível",
          message: "Serviço não está disponível no momento. Por favor, tente mais tarde.",
        }
      }
    }
  },
  dashboard: {
    title: "Dashboard",
    processConsultation: "Consulta do processo",
    sendInvoice: "Envio de nota fiscal",
    showProcess: "Visualizar processo",
    sections: {
      dashboard: {
        title: "Dashboard",
        contentTitle: "Outros sistemas",
        noContentsAvailable: "Não há sistemas disponíveis.",
      },
      newRequest: {
        title: "Nova Solicitação",
        openRequest: "Abrir nova solicitação",
        financings: {
          title: "Financeiro",
          sendInvoice: "Envio de nota fiscal",
          anticipatePayment: "Envio de nota fiscal",
          sendCopyPaymentSlip: "Envio de segunda via de boleto",
        },
        buy: {
          title: "Compras",
          buyQuotation: "Cotação de compras",
          divergentDelivery: "Entrega divergente",
          contractRenewal: "Renovação de contrato",
        },
        registrationCenter: {
          title: "Central de Cadastros",
          visitScheduling: "Programação de visitas",
          documentSending: "Envio de documentos",
          userRegistration: "Cadastro de novo usuário",
        },
      },
      myPendings: {
        title: "Minhas Pendências",
        contentTitle: "Minhas Pendências",
        detailCardTitle: "Leitura Rápida",
        buttonLabel: "Responder Processo",
      },
      inProgressRequests: {
        title: "Solicitações em Andamento",
        detailCardTitle: "Consulta do processo",
        contentTitle: "Minhas Solicitações em Andamento",
        buttonLabel: "Visualizar Processo",
      },
      closeRequests: {
        title: "Solicitações Encerradas",
        detailCardTitle: "Consulta do processo",
        contentTitle: "Minhas Solicitações Encerradas",
        buttonLabel: "Visualizar Processo",
      },
      notifications: {
        title: "Notifications",
        myNotifications: "Minhas Notificações",
      },
    },
  },
  auth: {
    email: "E-mail",
    password: "Senha",
    remember: "Manter conectado",
    logout: "Desconectar-se",
    forgot: "Esqueci minha senha",
    backToLogin: "Ir para Login",
    forgotSubTitle: "Será enviado um e-mail com o link para a geração de uma nova senha.",
    resetSubTitle: "Por favor, digite abaixo uma nova senha. A senha deve conter ao menos 8 caracteres, uma letra, um número e um caracter especial",
    createUser: "Criar um novo usuário",
    cpfCnpj: "CPF/CNPJ",
    notifications: {
      errors: {
        formObserverLogin: {
          title: "Dados insuficientes",
          message: "E-mail e/ou senha não foram informados ou foram preenchidos incorretamente. Por favor, verifique antes de continuar.",
        },
        formObserverForgot: {
          title: "Dados insuficientes",
          message: "E-mail inválido. Por favor, verifique antes de continuar.",
        },
        formObserverReset: {
          title: "Dados insuficientes",
          message: "Senha inválida. Por favor, verifique antes de continuar.",
        },
      },
      success: {
        forgotReset: {
          title: "E-mail enviado com sucesso!",
          message: "Foi enviado para seu e-mail o link para a recuperação da senha. Por gentileza, verifique seu e-mail.",
        },
        resetPassword: {
          title: "Senha alterada com sucesso!",
          message: "A sua senha foi alterada com sucesso.",
        },
      },
    },
  },
  user: {
    title: "Usuário",
    titlePl: "Usuários",
    termsAndConditionsAccept: "Eu li e concordo com os",
    termsAndConditions: "Termos e Condições",
    updateUser: "Atualizar Usuário",
    createUser: "Criar novo Usuário",
    editUser: "Editar Usuário",
    removeUser: "Suspender Usuário",
    enableUser: "Ativar Usuário",
    backToList: "Voltar para Lista",
    myAccount: "Minha Conta",
    accountType: "Conta",
    approveUser: "Aprovar Usuário",
    unlockUser: "Desbloquear Usuário",
    permissions: "Permissões",
    process: "Processos",
    searchProcess: "Pesquise pelo Num do Processo",
    approveDialogContent: "Tem certeza que deseja aprovar o usuário <b>{0}</b>?",
    unlockDialogContent: "Tem certeza que deseja desbloquear o usuário <b>{0}</b>?",
    removeDialogContent: "Tem certeza que deseja suspender o usuário <b>{0}</b>?",
    setPermissionsContent: "Selecione os serviços que serão habilitados para o usuário <b class='ml-1'>{0}</b>:",
    processesOpenedByUser: "Processos abertos pelo usuário <b class='ml-1'>{0}</b>:",
    lastLogin: "Último login",
    fields: {
      type: "Tipo de pessoa",
      name: "Nome",
      email: "E-mail",
      cpf: "CPF",
      password: "Senha",
      phone: "Telefone",
      status: "Status",
      admin: "Administrador",
    },
    type: {
      PF: 'Pessoa Física',
      PJ: 'Pessoa Jurídica'
    },
    status: {
      USU_ATIVO: 'Ativo',
      USU_BLOQUEADO: 'Bloqueado',
      USU_PENDENTE: 'Pendente',
      USU_PENDENTE_SENHA: 'Pendente Senha',
      USU_SUSPENSO: 'Suspenso',
      USU_PENDENTE_APROVACAO: 'Pendente de Aprovação',
    },
    notifications: {
      errors: {
        formObserverUser: {
          title: "Dados insuficientes",
          message: "Verifique se todos os campos do formulário foram preenchidos corretamente antes de continuar.",
        },
        removeUser: {
          title: "Usuário não pode ser suspenso!",
          message: "O usuário não pode ser suspenso.",
        },
      },
      success: {
        createUser: {
          title: "Usuário criado com sucesso!",
          message: "Foi enviado por e-mail o link para validar a criação do usuário. É necessário acessar o e-mail cadastrado e validar para ter acesso à ferramenta.",
        },
        updateUser: {
          title: "Usuário atualizado com sucesso!",
          message: "Usuário atualizado com sucesso.",
        },
        validateUser: {
          title: "Usuário validado com sucesso!",
          message: "O usuário foi validado com sucesso. Faça o login para acessar a ferramenta.",
        },
        removeUser: {
          title: "Usuário suspenso com sucesso!",
          message: "O usuário foi suspenso com sucesso.",
        },
        approveUser: {
          title: "Usuário aprovado com sucesso!",
          message: "O usuário foi aprovado com sucesso.",
        },
        unlockUser: {
          title: "Usuário desbloqueado com sucesso!",
          message: "O usuário foi desbloqueado com sucesso.",
        },
        saveUserPermissions: {
          title: "Permissões atualizadas com sucesso!",
          message: "As permissões foram atribuídas ao usuário com sucesso.",
        },
      },
    },
  },
  groups: {
    title: "Grupos",
    createGroup: "Criar grupo",
    addNewGroup: "Adicionar novo grupo",
    editGroup: "Editar grupo",
    updateGroup: "Atualizar grupo",
    iconHelper: "Escolha um ícone pelo site do <a href=\"https://materialdesignicons.com/\" rel=\"noopener noreferrer\" target=\"_blank\">Material Design</a> e preencha com o nome do ícone.",
    fields: {
      description: "Título",
      group: "Título",
      icon: "Ícone",
    },
    notifications: {
      errors: {
        formObserverGroup: {
          title: "Dados insuficientes",
          message: "Verifique se todos os campos do formulário foram preenchidos corretamente antes de continuar.",
        },
      },
      success: {
        saveGroupPermissions: {
          title: "Permissão de grupo salva com sucesso!",
          message: "Permissão de grupo salva com sucesso.",
        },
        createGroup: {
          title: "Grupo criado com sucesso!",
          message: "Grupo criado com sucesso.",
        },
        updateGroup: {
          title: "Grupo atualizado com sucesso!",
          message: "Grupo atualizado com sucesso.",
        },
      },
    },
  },
  services: {
    title: "Serviços",
    addNewService: "Adicionar novo serviço",
    editService:"Editar serviço",
    disableService:"Desativar serviço",
    enableService:"Ativar serviço",
    updateService:"Atualizar serviço",
    createService:"Criar serviço",
    listModels:"Lista de modelos",
    noServicesAvailable: "Não há serviços disponíveis.",
    iconHelper: "Escolha um ícone pelo site do <a href=\"https://materialdesignicons.com/\" rel=\"noopener noreferrer\" target=\"_blank\">Material Design</a> e preencha com o nome do ícone.",
    setPermissionsContent: "Selecione os grupos que serão habilitados para o serviço <b class='ml-1'>{0}</b>:",
    fields: {
      title: "Título",
      category: "Categoria",
      resume: "Descrição",
      active: "Ativo",
      model: "Modelo",
      icon: "Ícone",
      permission: "Exibir para todos usuários",
    },
    notifications: {
      errors: {
        formObserverService: {
          title: "Dados insuficientes",
          message: "Verifique se todos os campos do formulário foram preenchidos corretamente antes de continuar.",
        },
      },
      success: {
        saveServicePermissions: {
          title: "Permissões atualizadas com sucesso!",
          message: "As permissões foram atribuídas ao serviço com sucesso.",
        },
        createService: {
          title: "Serviço criado com sucesso!",
          message: "Serviço criado com sucesso.",
        },
        updateService: {
          title: "Serviço atualizado com sucesso!",
          message: "Serviço atualizado com sucesso.",
        },
      },
    },
  },
  categories: {
    title: "Categorias",
    addNewCategory: "Adicionar nova categoria",
    editCategory:"Editar categoria",
    disableCategory:"Desativar categoria",
    enableCategory:"Ativar categoria",
    updateCategory:"Atualizar categoria",
    createCategory:"Criar categoria",
    listModels:"Lista de categorias",
    noCategoriesAvailable: "Não há categorias disponíveis.",
    iconHelper: "Escolha um ícone pelo site do <a href=\"https://materialdesignicons.com/\" rel=\"noopener noreferrer\" target=\"_blank\">Material Design</a> e preencha com o nome do ícone.",
    fields: {
      description: "Título",
      category: "Categoria",
      resume: "Descrição",
      active: "Ativo",
      model: "Modelo",
      icon: "Ícone",
      permission: "Exibir para todos usuários",
    },
    notifications: {
      errors: {
        formObserverCategory: {
          title: "Dados insuficientes",
          message: "Verifique se todos os campos do formulário foram preenchidos corretamente antes de continuar.",
        },
      },
      success: {
        createCategory: {
          title: "Categoria criada com sucesso!",
          message: "Categoria criada com sucesso.",
        },
        updateCategory: {
          title: "Categoria atualizada com sucesso!",
          message: "Categoria atualizada com sucesso.",
        },
      },
    },
  },
  tabs: {
    title: "Abas",
    editTab:"Editar aba",
    updateTab:"Atualizar aba",
    fields: {
      tab: "Aba",
      active: "Ativo",
      name: "Nome",
      status: "Status",
    },
    notifications: {
      errors: {
        formObserverTab: {
          title: "Dados insuficientes",
          message: "Verifique se todos os campos do formulário foram preenchidos corretamente antes de continuar.",
        },
      },
      success: {
        createTab: {
          title: "Aba criada com sucesso!",
          message: "Aba criada com sucesso.",
        },
        updateTab: {
          title: "Aba atualizada com sucesso!",
          message: "Aba atualizada com sucesso.",
        },
      },
    },
  },
  administrator: {
    title: "Administrador",
    titlePl: "Administrador",
    usersAdministrator: {
      title: "Usuários",
    },
    groupsAdministrator: {
      title: "Grupos",
    },
  },
  
  configuration: {
    title: "Configuração",
    titlePl: "Configurações",
    layoutConfiguration: {
      title: "Layout",
      colors: "Cores",
      images: "Imagens",
      primary: "Cor primária",
      secondary: "Cor secundária",
      logoImage: "Imagem da Logo",
      logoImageHelper: "<b>Dimensões recomendadas:</b> 190x60<br><b>Tamanho máximo do arquivo:</b> {0}Mb<br><b>Extensões permitidas:</b> jpg, jpeg, png",
      bannerImage: "Imagem do Banner",
      bannerImageHelper: "<b>Dimensões recomendadas:</b> 1920x1080<br><b>Tamanho máximo do arquivo:</b> {0}Mb<br><b>Extensões permitidas:</b> jpg, jpeg, png",
      notifications: {
        success: {
          title: "Configuração atualizada com sucesso!",
          message: "Configurações de layout atualizadas com sucesso! Aguarde a página ser recarregada para visualizar as alterações.",
        },
      },
    },
    emailConfiguration: {
      title: "E-mail",
      smtpServer: "Servidor",
      smtpPort: "Porta",
      smtpEmail: "E-mail",
      smtpPassword: "Senha",
      fromEmail: "E-mail do remetente",
      useTls: "SSL/TLS",
      testEmailSend: {
        title: "Testar Envio",
        notifications: {
          error: {
            title: "Erro ao testar envio",
            message: "Verifique se todos os campos do formulário foram preenchidos corretamente antes de continuar.",
            testMessage: "Verifique se todos os campos preenchidos estão com valor válido.",
          },
          success: {
            title: "Teste de envio realizado com sucesso!",
            message: "Um e-mail de teste foi enviado para o usuário logado!",
          },
        }
      },
      notifications: {
        success: {
          title: "Configuração atualizada com sucesso!",
          message: "Configurações de e-mail atualizadas com sucesso!",
        },
      },
    },
    apiConfiguration: {
      title: "BPM",
      pathServiceLecomModel: "Path do modelo do BPM",
      pathServiceLecomAuthentication: "Path de autenticação do BPM",
      pathServiceLecomOpenProcess: "Path de abertura de processo do BPM",
      pathServiceLecomProcessInstances: "Path da instancia de processo do BPM",
      pathServiceLecomFormApp: "Path do form do BPM",
      serviceLecomAuthenticationAdminLogin: "Login do administrador do BPM",
      serviceLecomAuthenticationAdminPassword: "Senha do administrador do BPM",
      serviceLecomUserPublicLogin: "Login do usuário publico do BPM",
      serviceLecomUserPublicPassword: "Senha do usuário publico do BPM",
      serviceLecomUserPublicId: "ID do usuário publico do BPM",
      urlServiceLecomBpm: "Url do BPM",
      notifications: {
        success: {
          title: "Configuração atualizada com sucesso!",
          message: "Configurações do BPM atualizadas com sucesso!",
        },
      },
    },
    parameterConfiguration: {
      title: "Parâmetros",
      authenticationType: "Tipo de autenticação no login",
      loginParameterUrl: "Url redirectionar cadastro inválido",
      loginParameterApiUrl: "Url API validação de cadastro",
      loginParameterApiUser: "Usuário API validação de cadastro",
      loginParameterApiPassword: "Senha API validação de cadastro",
      oauthPkceUrl: "URL do Oauth PKCE",
      oauthPkceAuthorizeUrl: "URL Authorize do Oauth PKCE",
      oauthPkceTokenUrl: "URL Token do Oauth PKCE",
      oauthPkceUserInfoUrl: "URL UserInfo do Oauth PKCE",
      oauthPkceLogoutUrl: "URL Logout do Oauth PKCE",
      oauthPkcePostLogoutRedirectUrl: "URL PostLogoutRedirectUrl do Oauth PKCE",
      oauthPkceRedirectUri: "URL RedirectUri do Oauth PKCE",
      oauthPkceClientId: "ClientID do Oauth PKCE",
      oauthPkceClientSecret: "Secret do Oauth PKCE",
      oauthPkceGrantType: "GrantType do Oauth PKCE",
      oauthPkceScope: "Scopes do Oauth PKCE",
      oauthPkceIdField: "IdField do Oauth PKCE (Campo CPF)",
      oauthPkceLoginButtonEnabled: "Habilitar Botão de Login do Oauth PKCE",
      oauthPkceLoginButtonText: "Texto do Botão de Login do Oauth PKCE",
      govbrOauthPkceUrl: "URL SSO Gov BR",
      govbrOauthPkceAuthorizeUrl: "URL Authorize do Gov BR",
      govbrOauthPkceTokenUrl: "URL Token do Gov BR",
      govbrOauthPkceUserInfoUrl: "URL UserInfo do Gov BR",
      govbrOauthPkceLogoutUrl: "URL Logout do Gov BR",
      govbrOauthPkcePostLogoutRedirectUrl: "URL PostLogoutRedirectUrl do Gov BR",
      govbrOauthPkceRedirectUri: "URL RedirectUri do Gov BR",
      govbrOauthPkceClientId: "ClientID do Gov BR",
      govbrOauthPkceClientSecret: "Secret do Gov BR",
      govbrOauthPkceGrantType: "GrantType do Gov BR",
      govbrOauthPkceScope: "Scopes do Gov BR",
      govbrOauthPkceIdField: "IdField do Gov BR (Campo CPF)",
      govbrOauthPkceLoginButtonEnabled: "Habilitar Botão de Login do Gov BR",
      govbrOauthPkceLoginButtonText: "Texto do Botão de Login do Gov BR",
      govbrServiceLevelsUrl: "URL API Serviço Níveis do Gov BR",
      govbrServiceCategoriesUrl: "URL API Serviço Categorias do Gov BR",
      govbrServiceReliabilitiesUrl: "URL API Serviço Confiabilidades do Gov BR",
      govbrServiceCompaniesUrl: "URL API Serviço Empresas Vínculadas do Gov BR",
      govbrServiceCompanyDetailUrl: "URL API Serviço Detalhes da Empresa do Gov BR",
      urlAmbienteFront: "Url do ambiente de Front-end",
      apiTokenUser: "Usuário da API do Portal",
      apiTokenPassword: "Senha da API do Portal",
      authLayoutDirection: "Direção do Layout das tela de autenticação",
      failedLoginCount: "Quantia de tentavidas de login",
      appTitleClient: "Nome dos usuários do portal",
      appTitle: "Título do portal",
      userType: "Tipo de usuário do portal",
      companyRegistrationEnabledForPersonUser: "Habilitar registro de empresa para Pessoa Física",
      notifications: {
        success: {
          title: "Configuração atualizada com sucesso!",
          message: "Configurações do Parâmetro atualizadas com sucesso!",
        },
      },
    },
    contentConfiguration: {
      title: "Conteúdo",
      termsConditions: "Termos e Condições",
      notifications: {
        success: {
          title: "Configuração atualizada com sucesso!",
          message: "Configurações de conteúdo atualizadas com sucesso!",
        },
      },
    },
    tabConfiguration: {
      title: "Abas: As alterações serão aplicadas para todos os usuários",
    }
  },
  fileUpload: {
    fileTypeNotAllowed: "Tipo do arquivo inválido.",
    fileSizeNotAllowed: "Tamanho do arquivo excede o permitido.",
    notifications: {
      errors: {
        fileSizeError: {
          title: "Tamanho do arquivo excedido!",
          message: "O arquivo {0} excedeu o tamanho máximo permitido de {1}Mb.",
        },
        fileExtensionError: {
          title: "Tipo de arquivo não permitio!",
          message: "A extensão do arquivo {0} não é permitida.",
        },
      },
    },
  },
  buttons: {
    login: "Entrar",
    back: "Voltar",
    send: "Enviar",
    create: "Criar",
    close: "Fechar",
    save: "Salvar",
    backToList: "Voltar para lista",
    backToDashboard: "Voltar ao início",
    addProcess: "Iniciar Processo",
    answer: "Responder",
    cancel: "Cancelar",
    approve: "Aprovar",
    suspend: "Suspender",
    unlock: "Desbloquear",
    validate: "Validar",
  },
  labels: {
    or: "Ou",
    confirmPassword: "Confirmar Senha",
    clickToSelectAFile: "Clique para selecionar um arquivo...",
    actions: "Ações",
    help: "Ajuda",
  },
  badge: 'Distintivo',
  close: 'Fechar',
  dataIterator: {
    noResultsText: 'Nenhum dado encontrado',
    loadingText: 'Carregando itens...'
  },
  dataTable: {
    itemsPerPageText: 'Linhas por página:',
    ariaLabel: {
      sortDescending: 'Ordenado decrescente.',
      sortAscending: 'Ordenado crescente.',
      sortNone: 'Não ordenado.',
      activateNone: 'Ative para remover a ordenação.',
      activateDescending: 'Ative para ordenar decrescente.',
      activateAscending: 'Ative para ordenar crescente.'
    },
    sortBy: 'Ordenar por'
  },
  dataFooter: {
    itemsPerPageText: 'Itens por página:',
    itemsPerPageAll: 'Todos',
    nextPage: 'Próxima página',
    prevPage: 'Página anterior',
    firstPage: 'Primeira página',
    lastPage: 'Última página',
    pageText: '{0}-{1} de {2}'
  },
  datePicker: {
    itemsSelected: '{0} selecionado(s)',
    nextMonthAriaLabel: 'Próximo mês',
    nextYearAriaLabel: 'Próximo ano',
    prevMonthAriaLabel: 'Mês anterior',
    prevYearAriaLabel: 'Ano anterior'
  },
  noDataText: 'Não há dados disponíveis',
  carousel: {
    prev: 'Visão anterior',
    next: 'Próxima visão',
    ariaLabel: {
      delimiter: 'Slide {0} de {1} do carrossel'
    }
  },
  calendar: {
    moreEvents: 'Mais {0}'
  },
  fileInput: {
    counter: '{0} arquivo(s)',
    counterSize: '{0} arquivo(s) ({1} no total)'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  pagination: {
    ariaLabel: {
      wrapper: 'Navegação de paginação',
      next: 'Próxima página',
      previous: 'Página anterior',
      page: 'Ir à página {0}',
      currentPage: 'Página atual, página {0}'
    }
  },
  rating: {
    ariaLabel: {
      icon: 'Rating {0} de {1}'
    }
  }
};
